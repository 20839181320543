<template>
  <div class="matchCo">
    <Header
      back="返回"
      title="匹配工具"
      index="首页"
      titleOne="智能匹配"
      titleTwo="匹配工具"
      beforeTitle="企业基本信息"
    />
    <div class="content">
      <h1>企业基本信息</h1>
      <el-form
        ref="form"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="企业名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="注册类型">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.date1"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="注册地址">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="省">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="镇/模块">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上年总资产（万元）">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="上年销售收入（万元）">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="企业员工人数（人）">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="I知识产权数量（个）">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="II知识产权数量（个）">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="活动性质" style="width: 100%">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="高新技术企业" name="type"></el-checkbox>
            <el-checkbox label="瞪羚企业" name="type"></el-checkbox>
            <el-checkbox label="双软企业" name="type"></el-checkbox>
            <el-checkbox label="科技中小型企业" name="type"></el-checkbox>
            <el-checkbox label="小巨人企业" name="type"></el-checkbox>
            <el-checkbox label="技术创新示范企业" name="type"></el-checkbox>
            <el-checkbox label="知识产权示范企业" name="type"></el-checkbox>
            <el-checkbox label="技术先进型企业" name="type"></el-checkbox>
            <el-checkbox label="动漫企业" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <h5>其他</h5>
        <ul>
          <li>
            <span>有科技人员</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>有国家/省/市级人才</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>研发费用是否单独核算</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>是否有政府认定研发平台</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>是否为集成电路企业</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>是否有科技园/孵化器等创业平台</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>是否为软件企业</span>
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span
              >是否采购重大国产设备/进口设备(国产设备属于《科技开发、科学研究和教学设备清单》)</span
            >
            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
          <li>
            <span>是否有技术转让、技术开发、技术咨询、技术服务活动</span>

            <el-radio-group v-model="form.resource">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </li>
        </ul>
        <el-form-item style="width: 100%">
          <div class="foot_TJ">
            <el-button type="primary" @click="onSubmit">开始匹配</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "top",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style scoped>
.matchCo .el-form-item__label {
  width: 100% !important;
  text-align: left;
}

.matchCo .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.matchCo .el-select {
  width: 100%;
}

.matchCo .el-checkbox {
  float: left;
  color: #909399;
}
.matchCo ul li {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.matchCo ul li span {
  color: #909399;
}
.matchCo .el-radio-group {
  float: right;
}
.matchCo h5 {
  font-size: 16px;
  color: #cbcfe3;
  font-weight: 400;
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.el-form--label-top /deep/.el-form-item__label {
  color: #404040 !important;
}
</style>
